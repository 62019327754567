<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.5 10.8333C17.5 15.9258 15.8333 17.4999 10 17.4999C4.16667 17.4999 2.5 15.9258 2.5 10.4166C2.5 4.90733 4.16667 3.33325 10 3.33325C15.8333 3.33325 17.5 4.90733 17.5 10.8333Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3335 5L13.3335 2.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.6665 5L6.6665 2.5"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="6.4585"
      y1="8.54175"
      x2="13.5418"
      y2="8.54175"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UiIconCalendar",
};
</script>
